<template>
<div class="flex flex-column flex-auto">
    <div class="p-5">
        <div class="grid">
            <!-- add new -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <Button class="w-full mt-2" label="Add New" @click="addNewProvider" />
                </div>
            </div>

            <!-- body -->
            <div class="col-12">
                <div class="surface-card shadow-2 border-round p-3">
                    <DataTable :value="providers" sortField="Id" :sortOrder="1">
                        <Column field="Id" header="Id"></Column>
                        <Column field="Name" header="Name"></Column>
                        <Column field="Email" header="Email"></Column>
                        <Column field="domainString" header="Domains"></Column>
                        <Column field="AutomatedTakedowns" header="Automated Takedowns">
                            <template #body="{ data }">
                                <div style="text-align:center">
                                    <i class="pi text-2xl" :class="{
                                    'pi-check-circle text-green-500': data.AutomatedTakedowns,
                                    'pi-times-circle text-red-500': !data.AutomatedTakedowns
                                }"></i>
                                </div>
                            </template>
                        </Column>
<!--                        <Column field="SpamEmail" header="Spam Email">-->
<!--                            <template #body="{ data }">-->
<!--                                <div style="text-align:center">-->
<!--                                    <i class="pi text-2xl" :class="{-->
<!--                                    'pi-check-circle text-green-500': data.SpamEmail,-->
<!--                                    'pi-times-circle text-red-500': !data.SpamEmail-->
<!--                                }"></i>-->
<!--                                </div>-->
<!--                            </template>-->
<!--                        </Column>-->
                        <Column field="linkcount" header="Pending"></Column>
                        <Column value="Edit" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                            <template #body="{ data }">
                                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                    <i class="pi pi-pencil" @click="editProvider(data)"/>
                                </div>
                            </template>
                        </Column>
                        <Column value="Edit" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
                            <template #body="{ data }">
                                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                                    <i class="pi pi-trash" @click="confirmDelete($event, data)"/>
                                </div>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>
        </div>
    </div>
</div>
<ContentProviderDetailModal ref="contentProviderDetailModal"/>
</template>

<script>
import ContentProviderDetailModal from "@/components/modals/ContentProviderDetailModal";

export default {
    name: "ContentProvider",
    components: {ContentProviderDetailModal},
    data() {
        return {
            providers: [],
        }
    },

    mounted() {
        this.getProviders();

        this.$emitter.on("content-provider-refresh", () => {
            console.debug("content-provider-refresh");
            this.getProviders();
        });
    },

    methods: {
        getProviders() {
            this.$factory.linkRemoval.getProviders().then((response) => {
                console.debug(response)
                this.providers = response;

                // Convert domains to string with ... if too long
                this.providers.forEach((provider) => {
                    if (provider.domains.length > 0) {
                        provider.domainString = provider.domains.join(", ");
                    } else {
                        provider.domainString = "none";
                    }
                });
            });
        },

        editProvider(data) {
            this.$refs.contentProviderDetailModal.show(JSON.parse(JSON.stringify(data)));
        },

        addNewProvider() {
            this.$refs.contentProviderDetailModal.show();
        },

        confirmDelete(event, data) {
            this.$confirm.require({
                target: event.currentTarget,
                message: 'Are you sure you want to delete this provider?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.deleteProvider(data);
                },
            });
        },

        deleteProvider(data) {
            this.$factory.linkRemoval.deleteProvider(data.Id).then(() => {
                this.$emitter.emit("good-toast", "Provider deleted successfully")
                this.getProviders();
            }).catch(() => {
                this.$emitter.emit("bad-toast", "Failed to delete provider")
            })
        }
    }
}
</script>

<style scoped>

</style>

<template>
<Dialog v-model:visible="visible" modal :header="provider.Name" :style="{ width: '50vw' }">
    <!-- Provider Name -->
    <p class="mt-3 font-bold">Provider Name</p>
    <InputText class="w-full" v-model="provider.Name" />

    <!-- Provider Email -->
    <p class="mt-3 font-bold">DMCA Contact Email</p>
    <InputText class="w-full" v-model="provider.Email" />

    <!-- Domains -->
    <DataTable class="mt-3" :value="provider.domains">
        <Column field="domain" header="Domains"></Column>
        <Column value="Edit" style="width: 10%; min-width: 8rem" bodyStyle="text-align:center">
            <template #body="{ data }">
                <div class="border-circle hover:text-900 hover:surface-100 cursor-pointer w-1rem h-1rem">
                    <i class="pi pi-trash" @click="removeProviderDomain(data)"/>
                </div>
            </template>
        </Column>
    </DataTable>

    <p class="mt-3 font-bold">
        Add a new domain for this provider.
    </p>
    <div>
        <InputText class="w-full" v-model="newEntry" />
        <Button class="w-full mt-2" label="Add" @click="addNewEntry" />
    </div>

    <!-- Automated Takedowns and Spam email checkboxes-->
    <div class="card flex flex-wrap justify-content-start gap-3 mt-2">
        <div class="flex align-items-center mt-2 mx-4">
            <Checkbox v-model="takedownOptions" inputId="takedown" name="takedown" value="Automated Takedowns" />
            <label for="takedown" class="ml-2"> Automated Takedowns </label>
        </div>
<!--        <div class="flex align-items-center mt-2">-->
<!--            <Checkbox v-model="takedownOptions" inputId="spam" name="spam" value="Spam Email" />-->
<!--            <label for="spam" class="ml-2"> Spam Email </label>-->
<!--        </div>-->
    </div>

    <!-- Save buttons -->
    <template #footer>
        <Button class="w-full mt-2 p-button-success" label="Save" @click="save" />
    </template>

</Dialog>
</template>

<script>
export default {
    name: "ContentProviderDetailModal",

    data() {
        return {
            visible: false,
            provider: {
                Id: -1,
                Name: "",
                domains: [],
                Email: "",
                AutomatedTakedowns: false,
                SpamEmail: false,
                linkcount: 0,
                domainString: ""
            },
            loading: false,
            newEntry: "",

            takedownOptions: []

        }
    },

    mounted() {
        this.init();
    },

    unmounted() {
        this.init();
    },

    methods: {
        init() {
            this.visible = false;
            this.provider = {
                Id: -1,
                Name: "",
                domains: [],
                Email: "",
                AutomatedTakedowns: false,
                SpamEmail: false,
                linkcount: 0,
                domainString: ""
            };
        },

        show(provider) {
            this.init();
            if (provider) {
                this.provider = provider;
            }

            // Convert domains to array of objects with key of domain
            this.provider.domains = this.provider.domains.map(domain => {
                return {
                    domain: domain
                }
            })

            // Convert takedown and spam to array
            this.takedownOptions = this.provider.AutomatedTakedowns ? ["Automated Takedowns"] : []
            this.takedownOptions = this.provider.SpamEmail ? [...this.takedownOptions, "Spam Email"] : this.takedownOptions

            this.visible = true;
        },

        removeProviderDomain(domain) {
            this.provider.domains = this.provider.domains.filter(d => d.domain !== domain.domain)
        },

        addNewEntry() {
            if (this.newEntry.length > 0) {
                this.provider.domains.push({
                    domain: this.newEntry
                })
                this.newEntry = ""
            }
        },

        save() {
            this.provider.AutomatedTakedowns = this.takedownOptions.includes("Automated Takedowns")
            this.provider.SpamEmail = this.takedownOptions.includes("Spam Email")

            console.debug(this.provider)

            if (this.provider.Id === -1) {
                this.$factory.linkRemoval.addProvider(this.provider).then(() => {
                    this.$emitter.emit("good-toast", "Provider added successfully")
                    this.$emitter.emit('content-provider-refresh')
                    this.visible = false;
                }).catch(() => {
                    this.$emitter.emit("bad-toast", "Error adding provider, please try again.")
                })
            } else {
                this.$factory.linkRemoval.updateProvider(this.provider).then(() => {
                    this.$emitter.emit("good-toast", "Provider updated successfully")
                    this.$emitter.emit('content-provider-refresh')
                    this.visible = false;
                }).catch(() => {
                    this.$emitter.emit("bad-toast", "Error updating provider, please try again.")
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
